import { getBundleMap } from "./commonSelectors";
import { createSelector } from "reselect";
import { privilegedTeamId } from "../../service";
import { RootState } from "../reducers";
import { GlobalFeature } from "../../models";
import _ from "lodash";

export const selectedTeamSelector = (state: RootState) =>
    state.user.selectedTeamId;
export const getOptOutCompleted = (state: RootState) =>
    state.user.optOutCompleted;
export const getOptOutInProgress = (state: RootState) =>
    state.user.optOutInProgress;
export const getUserLocale = (state: RootState) => state.user.locale;

// Gets the currently selected team id or privileged id if applicable
export const selectedTeamIdSelector = createSelector(
    [selectedTeamSelector, (state: RootState) => state.user.isPrivileged],
    (teamId, isPrivileged) => {
        return isPrivileged ? privilegedTeamId : teamId;
    }
);

export const optOutHandlerSelector = createSelector(
    [getOptOutCompleted, getOptOutInProgress, getUserLocale, getBundleMap],
    (optOutCompleted, optOutInProgress, userLocale, bundleMap) => {
        return {
            optOutCompleted: optOutCompleted,
            optOutInProgress: optOutInProgress,
            userLocale: userLocale,
            bundleMap: bundleMap,
        };
    }
);

// CookieConsent
export const getCookieConsentSetting = (state: RootState) =>
    state.user.cookieConsentSetting;

export const getCookieModalText = (state: RootState) =>
    state.user.cookieModalText;

export const cookieConsentSelector = createSelector(
    [
        getCookieConsentSetting,
        getCookieModalText,
    ],
    (
        cookieConsentSetting,
        cookieModalText,
    ) => {
        return {
            cookieConsentSetting: cookieConsentSetting,
            cookieModalText: cookieModalText,
        };
    }
);
