import * as React from "react";
import { connect, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Switch from "react-switch";
import { LocalizedUrls, buttonIds, pageIds } from "@amzn/ziggy-asset";
import * as rootStyles from "../../styles";
import {
    settings,
    OptValue,
    updateSettingsPayload,
    METRIC_KEYS,
    telemetryPayload,
    teamInfo,
    hydrateCatalogPayload,
    CatalogItemType,
    userData,
    clientMetricsPayload,
    BundleMap,
    IntercomEvent,
} from "../../../models";
import {
    RootState,
    userActions,
    telemetryActions,
    catalogActions,
    clientMetricsActions,
    oAuthActions,
} from "../../../store";
import { stringIds, IconsList, bundleIds } from "../../../assets";
import {
    getLocalizedString,
    paths,
    invertOpt,
    translateLocaleStringToUserLanguage,
    getArtistHandbookUrl,
    getLocalizedUrl,
    webLocale,
    buildUIClickPayload,
} from "../../../utils";
import SettingsListItem from "../../components/lists/SettingsListItem";
import { getBundleMap } from "../../../store/selectors/commonSelectors";
import {
    styledTitle,
    Icon,
    LanguageModal,
    CommonHeader,
} from "../../components";

const testIDPrefix = "SettingsScreen";
const metricPrefix = "settingsPage";
const pagePath = paths.settings;
const supportUrl = "https://artists.amazonmusic.com/frequently-asked-questions";

type ViewProps = {};

type StateProps = {
    signedIn: boolean;
    settings: settings;
    updateSettingsInProgress: boolean;
    userLocale: string;
    userTeams?: teamInfo[];
    userData: userData;
    bundleMap: BundleMap;
};

type DispatchProps = {
    updateSettings: (settings: updateSettingsPayload) => void;
    userAction: (payload: telemetryPayload) => void;
    updateCurrentPath: (payload: string) => void;
    updateUserLocale: (locale: string) => void;
    hydrateUserTeams: (payload: hydrateCatalogPayload) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
    setIntercomEvent: (payload: IntercomEvent) => void;
    setHideIntercomLauncher: (payload: boolean) => void;
    setShowIntercomMessenger: (payload: boolean) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = {
    settings: settings;
    showSupportModal: boolean;
    userLocale: string;
    showLanguageModal: boolean;
};

export class SettingsScreen extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            settings: this.props.settings,
            showSupportModal: false,
            userLocale: this.props.userLocale,
            showLanguageModal: false,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        this.props.userAction({
            name: metricPrefix + "View",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.page, pagePath],
            ]),
        });
        this.setState({
            settings: this.props.settings,
        });
        this.props.setHideIntercomLauncher(false);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.settings !== prevProps.settings) {
            this.setState({
                settings: this.props.settings,
            });
        }
    }

    componentWillUnmount() {
        this.props.setHideIntercomLauncher(true);
    }

    render() {
        const rightArrow = (idSuffix: string) => {
            return (
                <Icon
                    size={rootStyles.icons.small}
                    icon={IconsList.chevron_caretright}
                    id={`${testIDPrefix}_${idSuffix}Icon`}
                />
            );
        };

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                <CommonHeader
                    title={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.SETTINGS_STRINGS,
                        stringId: stringIds.Settings.settings,
                    })}
                    id={`${testIDPrefix}_Header`}
                    hideArtistImgDropdown={true}
                    hideDatePicker={true}
                />
                <Col style={containerStyle}>
                    <Row style={{ ...rowStyle, borderBottomWidth: 0 }}>
                        <SettingsListItem
                            onClick={this.onClickAccountDetails}
                            key="accountDetails"
                            primary={this.props.userData.userName}
                            primaryJSXElement={(text: string) => (
                                <styledTitle.h4>{text}</styledTitle.h4>
                            )}
                            secondary={this.props.userData.emailAddress}
                            rightElement={rightArrow("AccountDetails")}
                            id={`${testIDPrefix}_EmailAddress`}
                        />
                    </Row>
                    <Row>
                        <styledTitle.h4
                            style={{ marginTop: rootStyles.spacers.epic }}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.SETTINGS_STRINGS,
                                stringId: stringIds.Settings.notificationsTitle,
                            })}
                        </styledTitle.h4>
                    </Row>
                    <Row style={rowStyle}>
                        <SettingsListItem
                            key="emailNotifications"
                            primary={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.SETTINGS_STRINGS,
                                stringId: stringIds.Settings.emailLabel,
                            })}
                            secondary={getLocalizedString(
                                this.props.bundleMap,
                                {
                                    bundleId: bundleIds.SETTINGS_STRINGS,
                                    stringId: stringIds.Settings.emailSubtitle,
                                }
                            )}
                            rightElement={
                                <Switch
                                    {...switchProps}
                                    onChange={this.toggleMarketingEmail}
                                    disabled={
                                        this.props.updateSettingsInProgress
                                    }
                                    checked={
                                        this.state.settings
                                            .marketingEmailSetting ===
                                        OptValue.OPT_IN
                                    }
                                />
                            }
                            id={`${testIDPrefix}_EmailNotifications`}
                        />
                    </Row>
                    <Row>
                        <styledTitle.h4
                            style={{ marginTop: rootStyles.spacers.epic }}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.SETTINGS_STRINGS,
                                stringId: stringIds.Settings.languageLabel,
                            })}
                        </styledTitle.h4>
                    </Row>
                    <Row style={rowStyle}>
                        <SettingsListItem
                            onClick={this.onClickLanguageModal}
                            key="userLanguage"
                            primary={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.SETTINGS_STRINGS,
                                stringId: stringIds.Settings.languageSubtitle,
                            })}
                            secondary={translateLocaleStringToUserLanguage(
                                this.state.userLocale
                            )}
                            rightElement={rightArrow("UserLanguage")}
                            id={`${testIDPrefix}_UserLanguage`}
                        />
                        {this.state.showLanguageModal ? (
                            <LanguageModal
                                isVisible={this.state.showLanguageModal}
                                onDismiss={(userSelectedLocale) =>
                                    this.onDismissLanguageModal(
                                        userSelectedLocale
                                    )
                                }
                                initialUserLocale={this.state.userLocale}
                                bundleMap={this.props.bundleMap}
                            />
                        ) : null}
                    </Row>
                    <Row style={rowStyle}>
                        <SettingsListItem
                            onClick={() =>
                                window.open(
                                    getLocalizedUrl(
                                        LocalizedUrls.CookieConsentCustomizeCookieUrl
                                    )
                                )
                            }
                            key="cookiePreference"
                            primary={getLocalizedString(
                                this.props.bundleMap,
                                {
                                    bundleId:
                                        bundleIds.COOKIE_CONSENT_STRINGS,
                                    stringId:
                                        stringIds.CookieConsent
                                            .selectCookiePreferences,
                                }
                            )}
                            rightElement={rightArrow("CookiePreference")}
                            id={`${testIDPrefix}_cookiePreference`}
                        />
                    </Row>
                    <Row>
                        <styledTitle.h4
                            style={{ marginTop: rootStyles.spacers.epic }}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.SETTINGS_STRINGS,
                                stringId: stringIds.Settings.helpTitle,
                            })}
                        </styledTitle.h4>
                    </Row>
                    <Row style={rowStyle}>
                        <SettingsListItem
                            onClick={this.onClickInAppSupport}
                            key="InAppSupport"
                            primary={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.CUSTOMER_SUPPORT_STRINGS,
                                stringId:
                                    stringIds.CustomerSupport.in_app_help_title,
                            })}
                            secondary={getLocalizedString(
                                this.props.bundleMap,
                                {
                                    bundleId:
                                        bundleIds.CUSTOMER_SUPPORT_STRINGS,
                                    stringId:
                                        stringIds.CustomerSupport
                                            .in_app_help_description,
                                }
                            )}
                            rightElement={rightArrow("InAppSupport")}
                            id={`${testIDPrefix}_InAppSupport`}
                        />
                    </Row>
                    <Row style={rowStyle}>
                        <SettingsListItem
                            onClick={this.onClickReportArtistProfileIssue}
                            key="reportArtistIssueLabel"
                            primary={getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                                stringId:
                                    stringIds.ArtistDisambiguation
                                        .reportArtistProfileIssueTitle,
                            })}
                            secondary={getLocalizedString(
                                this.props.bundleMap,
                                {
                                    bundleId:
                                        bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                                    stringId:
                                        stringIds.ArtistDisambiguation
                                            .helpReportArtistProfileIssueSubtitle,
                                }
                            )}
                            rightElement={rightArrow("ReportIssue")}
                            id={`${testIDPrefix}_ReportIssue`}
                        />
                    </Row>
                    <Row style={rowStyle}>
                        <SettingsListItem
                            onClick={() =>
                                (window.location.href = getArtistHandbookUrl())
                            }
                            key="resourcesLabel"
                            primary={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.SETTINGS_STRINGS,
                                stringId: stringIds.Settings.resourcesLabel,
                            })}
                            secondary={getLocalizedString(
                                this.props.bundleMap,
                                {
                                    bundleId: bundleIds.SETTINGS_STRINGS,
                                    stringId:
                                        stringIds.Settings.resourcesSubtitle,
                                }
                            )}
                            rightElement={rightArrow("Resources")}
                            id={`${testIDPrefix}_Resources`}
                        />
                    </Row>

                    <Row>
                        <styledTitle.h4
                            style={{ marginTop: rootStyles.spacers.epic }}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.GENERIC_STRINGS,
                                stringId: stringIds.Generic.about,
                            })}
                        </styledTitle.h4>
                    </Row>

                    <Row style={rowStyle}>
                        <SettingsListItem
                            onClick={this.onClickTermsText}
                            key="tocLabel"
                            primary={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.SETTINGS_STRINGS,
                                stringId: stringIds.Settings.tocLabel,
                            })}
                            secondary={getLocalizedString(
                                this.props.bundleMap,
                                {
                                    bundleId: bundleIds.SETTINGS_STRINGS,
                                    stringId: stringIds.Settings.tocSubtitle,
                                }
                            )}
                            rightElement={rightArrow("TermsAndConditions")}
                            id={`${testIDPrefix}_TermsAndConditions`}
                        />
                    </Row>

                    <Row
                        style={{
                            ...rowStyle,
                            marginBottom: rootStyles.spacers.epic,
                        }}
                    >
                        <SettingsListItem
                            key="licenses"
                            onClick={this.onShowOpenSource}
                            primary={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.SETTINGS_STRINGS,
                                stringId:
                                    stringIds.Settings.openSourceAttribution,
                            })}
                            rightElement={rightArrow("Licenses")}
                            id={`${testIDPrefix}_Licenses`}
                        />
                    </Row>
                </Col>
            </Container>
        );
    }

    private onClickLanguageModal = () => {
        this.setState({ showLanguageModal: true });
    };

    private onDismissLanguageModal = (selectedLanguage: string) => {
        this.onChangeUserLocale(selectedLanguage);
        this.setState({ showLanguageModal: false });
    };

    private onClickReportArtistProfileIssue = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.SettingMenu.reportIssue,
                pageIds.settings
            )
        );
        this.props.history.push(paths.reportArtistProfile);
    };

    private onClickInAppSupport = () => {
        this.props.setShowIntercomMessenger(true);
    };

    private onClickAccountDetails = () => {
        this.props.history.push(paths.accountDetails);
    };

    private onClickTermsText = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.SettingMenu.termsAndConditions,
                pageIds.settings
            )
        );
        this.props.history.push(paths.termsAndConditions);
    };

    private onShowOpenSource = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.SettingMenu.openSourceAttribution,
                pageIds.settings
            )
        );
        this.props.history.push(paths.openSourceAttribution);
    };

    private toggleMarketingEmail = () => {
        if (!this.props.signedIn) {
            return;
        }
        const newSettings: settings = {
            ...this.state.settings,
            marketingEmailSetting: this.state.settings.marketingEmailSetting
                ? invertOpt(this.state.settings.marketingEmailSetting)
                : undefined,
        };
        this.props.updateSettings({
            settings: newSettings,
            requestPath: pagePath,
        });
    };

    private onChangeUserLocale = (userSelectedLocale: string) => {
        if (this.state.userLocale === userSelectedLocale) {
            return;
        }
        this.setState({ userLocale: userSelectedLocale }, () =>
            this.storeState()
        );
        const newSettings: settings = {
            ...this.state.settings,
            userLocaleSetting: userSelectedLocale,
        };
        this.props.updateSettings({
            settings: newSettings,
            requestPath: pagePath,
        });
        const teamArtists: string[] = [];
        const userTeams = this.props.userTeams;
        if (userTeams) {
            userTeams.forEach(
                (team) =>
                    team &&
                    team.artistAsins &&
                    team.artistAsins.forEach((asin) => teamArtists.push(asin))
            );
            this.props.hydrateUserTeams({
                asins: teamArtists,
                type: CatalogItemType.Artists,
                locale: userSelectedLocale,
            });
        }
    };

    private storeState = () => {
        const locale = this.state.userLocale;
        this.props.updateUserLocale(locale);
        webLocale[0] = locale;
    };
}

const switchProps = {
    onColor: rootStyles.colors.accent,
};

const continueButton: React.CSSProperties = {
    marginBottom: 20,
    marginTop: 20,
    alignSelf: "stretch",
    marginLeft: 20,
    marginRight: 20,
    flex: 0,
};

const containerStyle: React.CSSProperties = {
    flex: 1,
    paddingLeft: rootStyles.spacers.medium,
    paddingRight: rootStyles.spacers.medium,
    marginTop: rootStyles.spacers.large,
};

const rowStyle: React.CSSProperties = {
    flex: 1,
    alignSelf: "stretch",
    borderBottomStyle: "solid",
    paddingBottom: 0,
    borderBottomColor: rootStyles.glassColors.primary3,
    borderBottomWidth: 1,
};

const webViewStyle: React.CSSProperties = {
    marginLeft: rootStyles.spacers.medium,
    marginRight: rootStyles.spacers.medium,
    marginBottom: rootStyles.spacers.giant,
    marginTop: rootStyles.spacers.giant,
    flex: 1,
    height: "400px",
};

function mapStateToProps(state: RootState): StateProps {
    return {
        signedIn: state.user.signedIn,
        settings: state.user.settings,
        updateSettingsInProgress: state.user.updateSettingsInProgress,
        userLocale: state.user.locale,
        userTeams: state.user.teams,
        userData: state.user.userData,
        bundleMap: getBundleMap(state),
    };
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>) {
    return {
        updateSettings: (settings: updateSettingsPayload) =>
            dispatch(userActions.updateSettings(settings)),
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        updateUserLocale: (locale: string) =>
            dispatch(userActions.updateUserLocale(locale)),
        hydrateUserTeams: (payload: hydrateCatalogPayload) =>
            dispatch(catalogActions.hydrateAsins(payload)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
        setIntercomEvent: (payload: IntercomEvent) =>
            dispatch(oAuthActions.setIntercomEvent(payload)),
        setHideIntercomLauncher: (payload: boolean) =>
            dispatch(oAuthActions.setHideIntercomLauncher(payload)),
        setShowIntercomMessenger: (payload: boolean) =>
            dispatch(oAuthActions.setShowIntercomMessenger(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SettingsScreen)
);
